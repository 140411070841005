/**
-----------------------------------------------------------------------------------------------------------------------------------------------
ACTION LINKS
-----------------------------------------------------------------------------------------------------------------------------------------------
*/
.action_links {
    transition: all 0.3s ease;
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: 0;
   
    z-index: 9;
    /* background: #ffffff; */
    background: #59a122;
    padding: 4px 7px;
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    @media (min-width: 992px){
        opacity: 0;
        visibility: hidden;
    }
}
.grid_list .action_links{
    left: 0;
    background: transparent;
}
.action_links ul {
    display: flex;
}

.action_links ul li {
    margin-right: 4px;
}

.action_links ul li:last-child {
    margin-right: 0;
}

.action_links ul li > span,
.action_links ul li a {
    font-size: 16px;
    display: inline-block;
    width: 33px;
    height: 33px;
    line-height: 36px;
    text-align: center;
    /* background: #fff; */
    background: #59962a;
    border-radius: 50%;
    color: #fff;
    &.no-link{
        cursor: initial;
        &:hover{
            
                background: #59962a;
           
        }
    }
}

.action_links ul li a.in-wish-list > span {
    color: #FC8A35;
}

.action_links ul li a.in-wish-list:hover > span {
    color: #59A122;
}

.action_links ul li a:hover {
    background: #59A122;
    color: #fff;
}

.remove-from-wishlist{
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
}
.remove-from-wishlist__link{
    font-size: 16px;
    display: inline-block;
    width: 33px;
    height: 33px;
    line-height: 36px;
    text-align: center;
    /* background: #fff; */
    background: #59962a;
    border-radius: 50%;
    color: #fff;
    &:hover {
        background: #FC8A35;
        color: #fff;
    }
    
}