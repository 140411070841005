.sbHolder {
	background-color: transparent;
	border: 1px solid #e6e6e6;
	font-size: 13px;
	line-height: 24px;
	height: 45px;
	position: relative;
	width: 100%;
	float:none;
}

.large-selectbox .sbHolder {
	height:55px;
	font-size:16px;
}

.medium-selectbox .sbHolder,
.small-selectbox .sbHolder {
	height:40px;
	font-size:12px;
	border-color:#ebebeb;
	color:#7c7c7c;
	font-weight: 500;
}

.medium-selectbox .sbHolder {
	height:35px;
}
.input-group .large-selectbox {
	display:table-cell;
}

.input-group .normal-selectbox {
	display:table-cell;
}

.sbSelector {
	display: block;
	height: 45px;
	left: 0;
	line-height:45px;
	outline: none;
	overflow: hidden;
	position: absolute;
	text-indent: 20px;
	top: 0;
	width: 100%;
}

.large-selectbox .sbSelector {
	height:55px;
	line-height:55px;
	text-indent: 25px;
}

.medium-selectbox .sbSelector {
	height:35px;
	line-height:35px;
	text-indent: 14px;
}

.small-selectbox .sbSelector {
	height:40px;
	line-height:40px;
	text-indent: 13px;
}

.sbSelector:link,
.sbSelector:visited,
.sbSelector:hover {
	color: inherit;
	outline: none;
	text-decoration: none;
}

.sbToggle {
	color: #6a6a6a;
	background: transparent;
	display: block;
	width: 45px;
	outline: none;
	position: absolute;
	right: 0;
	top: -1px;
	height: 45px;
	font-family: 'FontAwesome';
	font-size:16px;
	border-left:1px solid #e6e6e6;
}

.sbToggle:after {
	content: "\f107";
	position: absolute;
	right:0;
	top:0;
	display: inline-block;
	width:45px;
	height:45px;
	line-height: 45px;
	text-align: center;
}


.large-selectbox .sbToggle  {
	width: 55px;
	height:55px;
	right:-1px;
	font-size:18px;
}

.large-selectbox .sbToggle:after {
	width: 55px;
	height:55px;
	line-height: 55px;
}

.medium-selectbox .sbToggle  {
	width: 35px;
	height:35px;
	right:-1px;
	font-size:12px;
	color:#7c7c7c;
}

.medium-selectbox .sbToggle:after  {
	width: 35px;
	height:35px;
	line-height: 35px;
}

.small-selectbox .sbToggle  {
	width: 40px;
	height:40px;
	right:-1px;
	font-size:12px;
	color:#7c7c7c;
}

.small-selectbox .sbToggle:after  {
	width: 40px;
	height:40px;
	line-height: 40px;
}

.sbToggle.sbToggleOpen:after {
	content:"\f106";
}

.sbHolderDisabled{
	background-color: #999;
	border: solid 1px #aeaeae;
}

.sbOptions{
	background-color: #fff;
	list-style: none;
	border-bottom: 1px solid #eaeaea;
	left: -1px;
	margin:0 ;
	padding: 0;
	position: absolute;
	top: 40px;
	width: 100%;
	z-index: 800;
	overflow-y: auto;
	box-shadow: 0 2px 8px rgba(0,0,0, 0.12);
	-webkit-box-shadow: 0 2px 8px rgba(0,0,0, 0.12);
}

.accordion-panel-body ul.sbOptions {
	padding:0;
}

.sbOptions li{
	padding: 0;
	list-style-type:none !important;
}

.sbOptions a {
	display: block;
	outline: none;
	padding: 6px 0 6px 20px;
	font-size:13px;
}

.large-selectbox .sbOptions a {
	padding:9px 0 9px 25px;
	font-size:16px;
}

.medium-selectbox .sbOptions a {
	padding:2px 0 2px 14px;
	font-size:12px;
}

.small-selectbox .sbOptions a {
	padding:1px 0 1px 13px;
	font-size:12px;
}

.sbOptions a:link,
.sbOptions a:visited{
	color: inherit;
	text-decoration: none;
	transition:color 0s;
	-webkit-transition:color 0s;
	-moz-transition:color 0s;
	-ms-transition:color 0s;
	-o-transition:color 0s;
}

.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus{
	color: #fff;
	background-color: rgb(23, 161, 23);
}

.sbOptions li.last a{
	border-bottom: none;
}

.sbOptions .sbDisabled{
	border-bottom: dotted 1px #515151;
	color: #999;
	display: block;
	padding: 7px 0 7px 3px;
}

.sbOptions .sbGroup{
	border-bottom: dotted 1px #515151;
	color: #EBB52D;
	display: block;
	font-weight: bold;
	padding: 7px 0 7px 3px;
}

.sbOptions .sbSub{
	padding-left: 17px;
}