.cart_page table thead tr:last-child th,
.table_desc table tbody tr td:last-child {
  border-right: 0;
}

.cart_submit {
  text-align: right;
  padding: 12px;
}

@media only screen and (max-width: 767px) {
  .cart_submit {
    text-align: center;
  }
}

.cart_submit a,
.cart_submit button {
  background: #222222;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
  &:hover {
    background: #59a122;
  }
}

.coupon_inner {
  padding: 10px 20px 20px 20px;
  border-bottom: 1px solid #ededed;
  &:last-child {
    border-bottom: none;
  }
}

.coupon_inner p {
  font-size: 14px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.coupon_inner button {
  background: #222222;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 38px;
  line-height: 20px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
}

.coupon_inner button:hover {
  background: #59a122;
}

.coupon_inner input {
  border: 1px solid #ededed;
  height: 42px;
  background: none;
  padding: 0 20px;
  margin-right: 20px;
  font-size: 12px;
  color: #222222;
}

@media only screen and (max-width: 767px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}

.coupon_inner a {
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
  border-radius: 3px;
}

.coupon_inner a:hover {
  color: #59a122;
}

.coupon_code {
  border: 1px solid #ededed;
}

@media only screen and (max-width: 767px) {
  .coupon_code.left {
    margin-bottom: 59px;
  }
}

.coupon_code h3 {
  color: #ffffff;
  line-height: 36px;
  padding: 5px 15px;
  background: #222222;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .coupon_code h3 {
    line-height: 28px;
    padding: 5px 15px;
    font-size: 15px;
  }
}

.cart_subtotal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart_subtotal p {
  font-weight: 600;
  font-size: 14px;
}

.cart_subtotal p.cart_amount {
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .cart_subtotal p.cart_amount {
    font-size: 14px;
  }
}

.cart_subtotal p span {
  margin-right: 30px;
}

.checkout_btn {
  text-align: right;
}

.checkout_btn a {
  display: block;
  background: #59a122;
  color: #ffffff;
  font-size: 15px;
  padding: 3px 14px;
  line-height: 30px;
  font-weight: 500;
  // display: inline-block;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0;
}

.checkout_btn a:hover {
  background: #222222;
  color: #ffffff;
}

.coupon_area {
  margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .coupon_area {
    margin-bottom: 60px;
  }
}


.tab-pane-bordered{
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.checkout-total{
  text-transform: uppercase;
  color: #ffffff;
  background: #222222;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  display: flex;
  justify-content:space-between;
}
.checkout-total {

}