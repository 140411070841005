@charset "UTF-8";
.sbHolder {
  background-color: transparent;
  border: 1px solid #e6e6e6;
  font-size: 13px;
  line-height: 24px;
  height: 45px;
  position: relative;
  width: 100%;
  float: none;
}

.large-selectbox .sbHolder {
  height: 55px;
  font-size: 16px;
}

.medium-selectbox .sbHolder,
.small-selectbox .sbHolder {
  height: 40px;
  font-size: 12px;
  border-color: #ebebeb;
  color: #7c7c7c;
  font-weight: 500;
}

.medium-selectbox .sbHolder {
  height: 35px;
}

.input-group .large-selectbox {
  display: table-cell;
}

.input-group .normal-selectbox {
  display: table-cell;
}

.sbSelector {
  display: block;
  height: 45px;
  left: 0;
  line-height: 45px;
  outline: none;
  overflow: hidden;
  position: absolute;
  text-indent: 20px;
  top: 0;
  width: 100%;
}

.large-selectbox .sbSelector {
  height: 55px;
  line-height: 55px;
  text-indent: 25px;
}

.medium-selectbox .sbSelector {
  height: 35px;
  line-height: 35px;
  text-indent: 14px;
}

.small-selectbox .sbSelector {
  height: 40px;
  line-height: 40px;
  text-indent: 13px;
}

.sbSelector:link,
.sbSelector:visited,
.sbSelector:hover {
  color: inherit;
  outline: none;
  text-decoration: none;
}

.sbToggle {
  color: #6a6a6a;
  background: transparent;
  display: block;
  width: 45px;
  outline: none;
  position: absolute;
  right: 0;
  top: -1px;
  height: 45px;
  font-family: "FontAwesome";
  font-size: 16px;
  border-left: 1px solid #e6e6e6;
}

.sbToggle:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.large-selectbox .sbToggle {
  width: 55px;
  height: 55px;
  right: -1px;
  font-size: 18px;
}

.large-selectbox .sbToggle:after {
  width: 55px;
  height: 55px;
  line-height: 55px;
}

.medium-selectbox .sbToggle {
  width: 35px;
  height: 35px;
  right: -1px;
  font-size: 12px;
  color: #7c7c7c;
}

.medium-selectbox .sbToggle:after {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.small-selectbox .sbToggle {
  width: 40px;
  height: 40px;
  right: -1px;
  font-size: 12px;
  color: #7c7c7c;
}

.small-selectbox .sbToggle:after {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.sbToggle.sbToggleOpen:after {
  content: "";
}

.sbHolderDisabled {
  background-color: #999;
  border: solid 1px #aeaeae;
}

.sbOptions {
  background-color: #fff;
  list-style: none;
  border-bottom: 1px solid #eaeaea;
  left: -1px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: 800;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
}

.accordion-panel-body ul.sbOptions {
  padding: 0;
}

.sbOptions li {
  padding: 0;
  list-style-type: none !important;
}

.sbOptions a {
  display: block;
  outline: none;
  padding: 6px 0 6px 20px;
  font-size: 13px;
}

.large-selectbox .sbOptions a {
  padding: 9px 0 9px 25px;
  font-size: 16px;
}

.medium-selectbox .sbOptions a {
  padding: 2px 0 2px 14px;
  font-size: 12px;
}

.small-selectbox .sbOptions a {
  padding: 1px 0 1px 13px;
  font-size: 12px;
}

.sbOptions a:link,
.sbOptions a:visited {
  color: inherit;
  text-decoration: none;
  transition: color 0s;
  -webkit-transition: color 0s;
  -moz-transition: color 0s;
  -ms-transition: color 0s;
  -o-transition: color 0s;
}

.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus {
  color: #fff;
  background-color: #17a117;
}

.sbOptions li.last a {
  border-bottom: none;
}

.sbOptions .sbDisabled {
  border-bottom: dotted 1px #515151;
  color: #999;
  display: block;
  padding: 7px 0 7px 3px;
}

.sbOptions .sbGroup {
  border-bottom: dotted 1px #515151;
  color: #EBB52D;
  display: block;
  font-weight: bold;
  padding: 7px 0 7px 3px;
}

.sbOptions .sbSub {
  padding-left: 17px;
}

.cart_page table thead tr:last-child th,
.table_desc table tbody tr td:last-child {
  border-right: 0;
}

.cart_submit {
  text-align: right;
  padding: 12px;
}

@media only screen and (max-width: 767px) {
  .cart_submit {
    text-align: center;
  }
}
.cart_submit a,
.cart_submit button {
  background: #222222;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
}
.cart_submit a:hover,
.cart_submit button:hover {
  background: #59a122;
}

.coupon_inner {
  padding: 10px 20px 20px 20px;
  border-bottom: 1px solid #ededed;
}
.coupon_inner:last-child {
  border-bottom: none;
}

.coupon_inner p {
  font-size: 14px;
  margin-bottom: 20px;
}
.coupon_inner p:last-child {
  margin-bottom: 0;
}

.coupon_inner button {
  background: #222222;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  height: 38px;
  line-height: 20px;
  padding: 10px 15px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 3px;
}

.coupon_inner button:hover {
  background: #59a122;
}

.coupon_inner input {
  border: 1px solid #ededed;
  height: 42px;
  background: none;
  padding: 0 20px;
  margin-right: 20px;
  font-size: 12px;
  color: #222222;
}

@media only screen and (max-width: 767px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coupon_inner input {
    margin-bottom: 24px;
    width: 100%;
  }
}
.coupon_inner a {
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
  border-radius: 3px;
}

.coupon_inner a:hover {
  color: #59a122;
}

.coupon_code {
  border: 1px solid #ededed;
}

@media only screen and (max-width: 767px) {
  .coupon_code.left {
    margin-bottom: 59px;
  }
}
.coupon_code h3 {
  color: #ffffff;
  line-height: 36px;
  padding: 5px 15px;
  background: #222222;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .coupon_code h3 {
    line-height: 28px;
    padding: 5px 15px;
    font-size: 15px;
  }
}
.cart_subtotal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart_subtotal p {
  font-weight: 600;
  font-size: 14px;
}

.cart_subtotal p.cart_amount {
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .cart_subtotal p.cart_amount {
    font-size: 14px;
  }
}
.cart_subtotal p span {
  margin-right: 30px;
}

.checkout_btn {
  text-align: right;
}

.checkout_btn a {
  display: block;
  background: #59a122;
  color: #ffffff;
  font-size: 15px;
  padding: 3px 14px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0;
}

.checkout_btn a:hover {
  background: #222222;
  color: #ffffff;
}

.coupon_area {
  margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .coupon_area {
    margin-bottom: 60px;
  }
}
.tab-pane-bordered {
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.checkout-total {
  text-transform: uppercase;
  color: #ffffff;
  background: #222222;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}

.collapse-checkbox__label {
  display: flex;
  align-items: center;
}

.account_form .collapse-checkbox {
  font-size: 16px;
  height: 16px;
  width: 16px;
  margin-left: 10px;
}

/**
-----------------------------------------------------------------------------------------------------------------------------------------------
ACTION LINKS
-----------------------------------------------------------------------------------------------------------------------------------------------
*/
.action_links {
  transition: all 0.3s ease;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  bottom: 0;
  z-index: 9;
  /* background: #ffffff; */
  background: #59a122;
  padding: 4px 7px;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
@media (min-width: 992px) {
  .action_links {
    opacity: 0;
    visibility: hidden;
  }
}

.grid_list .action_links {
  left: 0;
  background: transparent;
}

.action_links ul {
  display: flex;
}

.action_links ul li {
  margin-right: 4px;
}

.action_links ul li:last-child {
  margin-right: 0;
}

.action_links ul li > span,
.action_links ul li a {
  font-size: 16px;
  display: inline-block;
  width: 33px;
  height: 33px;
  line-height: 36px;
  text-align: center;
  /* background: #fff; */
  background: #59962a;
  border-radius: 50%;
  color: #fff;
}
.action_links ul li > span.no-link,
.action_links ul li a.no-link {
  cursor: initial;
}
.action_links ul li > span.no-link:hover,
.action_links ul li a.no-link:hover {
  background: #59962a;
}

.action_links ul li a.in-wish-list > span {
  color: #FC8A35;
}

.action_links ul li a.in-wish-list:hover > span {
  color: #59A122;
}

.action_links ul li a:hover {
  background: #59A122;
  color: #fff;
}

.remove-from-wishlist {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}

.remove-from-wishlist__link {
  font-size: 16px;
  display: inline-block;
  width: 33px;
  height: 33px;
  line-height: 36px;
  text-align: center;
  /* background: #fff; */
  background: #59962a;
  border-radius: 50%;
  color: #fff;
}
.remove-from-wishlist__link:hover {
  background: #FC8A35;
  color: #fff;
}

.sort-order-select__sec {
  width: 250px;
}

.toasted.bubble {
  font-weight: 400;
}
.toasted.bubble > i {
  margin-right: 10px;
}
.toasted.bubble .action i {
  font-family: "plaza-icon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.toasted.bubble .action i:before {
  content: "";
}
.toasted.bubble.removed-toast {
  background-color: #ec391a;
  color: #fff;
}
.toasted.bubble.removed-toast .action {
  color: #fff;
  font-weight: bold;
}

@media (min-width: 992px) {
  .header_mob_menu {
    display: none;
  }
}