@import "selectbox";
@import "layout/cart";
@import "layout/form";
@import "elements/action_links";

.sort-order-select__sec {
  width: 250px;
}

.toasted.bubble {
  font-weight: 400;
  & > i {
    margin-right: 10px;
  }
  .action {
    i {
      font-family: "plaza-icon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      &:before {
        content: "\ea02";
      }
    }
  }

  &.removed-toast {
    background-color: rgb(236, 57, 26);
    color: #fff;
    .action {
      color: #fff;
      font-weight: bold;
    }
  }
}


.header_mob_menu{
  @media  (min-width: 992px){
    display: none;
  }
}