.collapse-checkbox__label{
    display: flex;
    align-items:center;

}

.account_form .collapse-checkbox{
    font-size: 16px;
    height: 16px;
    width: 16px;
    margin-left: 10px;
}